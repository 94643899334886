.react-calendar {
    width: 84% !important;
    margin: auto;
    border: none !important;
    text-decoration: none !important;
    color: #3C3E49 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: "Lato" !important;
    font-display: swap;
}
.react-calendar *{
 text-decoration: none !important;
}
@media screen and (max-width:700px) {
    .react-calendar {
        width: 100% !important;
        margin: auto;
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        font-family: "Lato" !important;
        font-display: swap;
    }
}
/* Updated style for non-weekend days */
.react-calendar__month-view__days__day {
    color: #3C3E49 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: "Lato" !important;
    font-display: swap;
}
/* Remove dotted underline and change color for all days */
.react-calendar__tile {
    text-decoration: none !important;
    /* Remove underline */
    color: var(--website-builder-secondary-color) !important;
    /* Change text color */
    background-color: #FFFFFF !important;
}
.react-calendar__tile--active {
    background-color: var(--website-builder-primary-color) !important;
    color: #FFF !important;
    border-radius: 50%;
    width: 3em;
    height: 3em;
}
/* @media screen and (min-width: 1025px) {
    .react-calendar__tile {
        text-decoration: none !important;
        color: var(--website-builder-secondary-color) !important;
        background-color: #FFFFFF !important;
        height: 100px;
        width: 50px;
        max-height: none !important;
        margin: 20px
    }
    .react-calendar__tile--active {
        background-color: var(--website-builder-primary-color) !important;
        color: #FFF !important;
        border-radius: 50%;
    }
} */
.react-calendar__tile abbr{
    font-family: var(--website-builder-body-font) !important;
    font-weight: 600 !important;
}
.react-calendar__tile--active abbr{
    font-family: var(--website-builder-body-font) !important;
}
.react-calendar__tile:disabled abbr{
    font-family: var(--website-builder-body-font) !important;
}
.react-calendar__month-view__weekdays {
    color: var(--website-builder-secondary-color) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    font-family: var(--website-builder-body-font) !important;
    font-display: swap;
    text-transform: capitalize !important;
}
.react-calendar__month-view__weekdays abbr {
    font-family: var(--website-builder-body-font) !important;
}
.react-calendar__tile:disabled {
    background-color: #FFF !important;
    color: var(--website-builder-secondary-color) !important;
    pointer-events: none;
    opacity: 0.6;
}
/* .react-calendar__month-view__days__day--neighboringMonth {
    background-color: #FFF !important;
    color: var(--website-builder-secondary-color) !important;
    pointer-events: none;
    opacity: 0.6;
} */
.MuiSvgIcon-root-MuiStepIcon-root {
    height: 50px !important;
    width: 50px !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
    color: var(--website-builder-primary-color) !important;
    width: 34px!important;
    height: 33.67px!important;
}
.MuiStepIcon-root.MuiStepIcon-completed {
    color: var(--website-builder-primary-color) !important;
    width: 34px!important;
    height: 33.67px!important;
}
.MuiStepIcon-text {
    fill:#FFFFFF !important;
    font-weight: 600 !important;
    font-family: "Lato" !important;
    font-display: swap;
    font-size: 14px !important;
    line-height: 24px !important;
}
/* Update the style of the month navigation label and arrow */
.react-calendar__navigation__label {
    /* Remove underline and clickable behavior */
    text-decoration: none !important;
    pointer-events: none !important;
    /* Set text color to red */
    color: var(--website-builder-secondary-color) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: var(--website-builder-body-font) !important;
    font-display: swap;
    text-transform: capitalize !important;
}
.react-calendar__navigation__label__labelText {
    font-family: var(--website-builder-body-font) !important;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    /* Remove underline and clickable behavior */
    text-decoration: none;
    pointer-events: none;
    /* Set arrow color to red */
    color: var(--website-builder-secondary-color) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: "Lato" !important;
    font-display: swap;
    text-transform: capitalize !important;
}
.MuiStepIcon-root {
    color: #8C8C8C !important;
    width: 34px!important;
    height: 33.67px!important;
}
.MuiStepLabel-label {
    font-weight: 600 !important;
    font-family: var(--website-builder-body-font) !important;
    font-display: swap;
    font-size: 14px !important;
    line-height: 24px !important;
}
.MuiButton-label{
    text-transform: capitalize;
}
@media screen and (max-width:700px) {
    .MuiStepIcon-root {
        color: #8C8C8C !important;
        width: 24px!important;
        height: 24px!important;
    }
    .MuiStepIcon-root.MuiStepIcon-active {
        color: var(--website-builder-primary-color) !important;
        width: 24px!important;
        height: 24px!important;
    }
    .MuiStepIcon-root.MuiStepIcon-completed {
        color: var(--website-builder-primary-color) !important;
        width: 24px!important;
        height: 24px!important;
    }
    .MuiStepIcon-text {
        fill:#FFFFFF !important;
        font-weight: 600 !important;
        font-family: "Lato" !important;
        font-display: swap;
        font-size: 10px !important;
        line-height: 24px !important;
    }
    .MuiStepLabel-label {
        font-weight: 600 !important;
        font-family: var(--website-builder-body-font) !important;
        font-display: swap;
        font-size: 12px !important;
        line-height: 24px !important;
    }
}
.MuiFormControlLabel-label.Mui-disabled {
    font-family: var(--website-builder-body-font) !important;
    font-display: swap;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 20px !important;
    color: #BFBFBF !important;
}
.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
    font-family: var(--website-builder-body-font) !important;
    font-display: swap;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 20px !important;
    color: var(--website-builder-secondary-color);
}